import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {BaseControllerComponent} from "../../../base-components";
import {ReferentialService} from "../../../../services/referential.service";

@Component({
  selector: 'password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent extends BaseControllerComponent {

  passwordChangeForm: FormGroup;
  displayPasswordChange: boolean = false;
  selectedUser:any = undefined;
  @Output() passwordChangeEvent = new EventEmitter<any>();
  constructor(private formBuilder: FormBuilder, private referentialService: ReferentialService) {
    super();
    this.passwordChangeForm = this.formBuilder.group({
      userId: [{value: this.userService.getUserInfo() ? this.userService.getUserInfo().id : null}],
      oldPassword: [{value: this.selectedUser ? null: null}],
      newPassword: [{value: this.selectedUser ? null: null}],
    });
  }

  @Input()
  set display(show: Observable<boolean>) {
    if (show) {
      show.subscribe((value => {
        this.displayPasswordChange = value
      }))
    }
  }

  @Input()
  set data(data: Observable<any>) {
    if (data) {
      data.subscribe((value => {
        this.selectedUser = value;
        this.passwordChangeForm.patchValue({
          activityId: value,
          comment: null
        });
      }))
    }
    else
      console.log("INPUT NO PROVIDED!!!!!!!!!")
  }

  ngOnInit() {

  }

  cancelAddComment (): void {

    this.passwordChangeForm.reset();
    this.displayPasswordChange = false;
  }
  saveComment(): void {
    console.log(this.passwordChangeForm.value);
    let data = this.passwordChangeForm.value;
    console.log("DATA TO SEND", data);
    this.userService.updatePassword(data).subscribe({
      next: response  => {
        this.messageSuccess("activity comment added");
        this.passwordChangeForm.reset();
        this.passwordChangeEvent.emit("saved");
        this.displayPasswordChange = false;
      },
      error: error => {
        this.messageError("unable to add activity");
      }
    });
  }
}
