import { Injectable } from '@angular/core';
import { BaseServiceService } from "./base-service.service";

@Injectable({
  providedIn: 'root'
})

export class InvestorService extends BaseServiceService {
  constructor() {
    super();
  }

  getInvestorList(params: any): any {
    return this.apiPost("/investor/" + this.selectedWorkspace, params);
  }

  getInvestorDataById(params: any): any {
    return this.apiGet("/investor/" + this.selectedWorkspace + "/" + params.id);
  }

}
