<p-dialog [(visible)]="displayPasswordChange" header="Change password" styleClass="w-auto">
  <div class="card">
    <form [formGroup]="passwordChangeForm" class="transaction-form">
      <div class="col-12">
        <div class="mb-3 ">
          <div class="row g-3">
            <div class="col-12">
          <span class="p-float-label">
                <p-password [feedback]="false"  formControlName="oldPassword" id="oldPassword"></p-password>
              <label htmlFor="oldPassword">Current password</label>
          </span>
            </div>
          </div>
        </div>
          <div class="mb-3 ">
            <div class="row g-3">
              <div class="col-12">
          <span class="p-float-label">
            <p-password [feedback]="false"  formControlName="newPassword" id="newPassword"></p-password>
              <label htmlFor="newPassword">New password</label>
          </span>
              </div>
            </div>
          </div>
      </div>
      <div  class="text-center">
        <button (click)="cancelAddComment()" class="btn btn-primary me-1">Cancel</button>
        <button (click)="saveComment()" class="btn btn-secondary">Update password</button>
      </div>
    </form>
  </div>
</p-dialog>
