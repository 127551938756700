<div [class.active]="navServices.language" class="translate_wrapper">
  <div class="current_lang">
    <div class="lang btn btn-danger-light">
      <i class="flag-icon flag-icon-{{selectedLanguage.icon}}"></i><span
        class="lang-txt">{{selectedLanguage.code}}</span>
    </div>
  </div>
  <div [class.active]="navServices.language" class="more_lang">
    <div (click)="changeLanguage(lang)" *ngFor="let lang of languages" class="lang selected" data-value="en">
      <i class="flag-icon flag-icon-{{lang.icon}}"></i>
      <span class="lang-txt">{{lang.language}}<span *ngIf="lang.type">({{lang.type}})</span></span>
    </div>
  </div>
</div>
