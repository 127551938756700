import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {SupabaseService} from "../services/supabase.service";
import {UserService} from "../services/user.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  session = this.supabase.session
  constructor(public router: Router,
              public supabase: SupabaseService,
              public userService: UserService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // if (this.supabase._session) {
    //   const user = this.supabase?._session?.user;
    //   this.userService.checkUser(user?.email).subscribe({
    //     next: response => {
    //       console.log("[ADMIN GUARD CALLED]");
    //       return true;
    //     },
    //     error: error => {
    //       console.log("[ADMIN GUARD IMPOSSIBLE TO GET USER]", error);
    //       this.router.navigate(["/"]);
    //     }
    //   });
    //   return true;
    // } else {
    //   this.router.navigate(["/"]);
    // }

    return true;
  }
}
