import {Injectable, OnDestroy} from "@angular/core";
import {BehaviorSubject, fromEvent, Subject} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Router} from "@angular/router";
import {AppConst} from "src/app/App.Const";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
  // Search Box
  public search: boolean = false;
  // Language
  public language: boolean = false;
  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;
  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;
  // Full screen
  public fullScreen: boolean = false;
  MENUITEMS: Menu[] = [
    // {
    //   headTitle1: "Investor",
    // },
    {
      title: "Investors",
      icon: "user",
      type: "sub",
      // badgeType: "light-primary",
      // badgeValue: "5",
      active: true,
      children: [
        { path: AppConst.MAIN_ROUTES.INVESTORS + "/" + AppConst.MAIN_ROUTES.INVESTOR, title: "Investor List", type: "link" },
        { path: AppConst.MAIN_ROUTES.INVESTORS + "/" + AppConst.MAIN_ROUTES.CANDIDATES, title: "Candidate", type: "link" },
      //{ path: AppConst.MAIN_ROUTES.INVESTORS + "/" + AppConst.MAIN_ROUTES.COMPLIANCE, title: "Compliance", type: "link" },
      ],
    },
    {
      title: "Transactions",
      icon: "ecommerce",
      type: "sub",
      // badgeType: "light-primary",
      // badgeValue: "5",
      active: false,
      children: [
        { path: AppConst.MAIN_ROUTES.TRANSACTION + "/" + AppConst.MAIN_ROUTES.BLOCKCHAIN , title: "Blockchain Transaction", type: "link" },
        { path: AppConst.MAIN_ROUTES.TRANSACTION + "/" + AppConst.MAIN_ROUTES.BANK, title: "Bank Transaction", type: "link" },
        {
          title: "Subscriptions",
          type: "sub",
          children: [
            { path: AppConst.MAIN_ROUTES.TRANSACTION + "/" + AppConst.MAIN_ROUTES.ORDER, title: "Payment Order", type: "link" },
            //{ path: AppConst.MAIN_ROUTES.TRANSACTION + "/" + AppConst.MAIN_ROUTES.ORDER, title: "monthly subscription", type: "link" },
           //{ path:AppConst.MAIN_ROUTES.TRANSACTION + "/" +  AppConst.MAIN_ROUTES.ORDER + "/" + AppConst.MAIN_ROUTES.ORDER_LIST, title: "Matched payment", type: "link" },
         // { path: AppConst.MAIN_ROUTES.TRANSACTION + "/" + AppConst.MAIN_ROUTES.ORDER + "/" + AppConst.MAIN_ROUTES.ORDER_LIST, title: "ovverdue payment", type: "link" },
          ]
        }

      ],
    },
    {
      title: "Activities",
      icon: "ecommerce",
      path: AppConst.MAIN_ROUTES.ACTIVITIES,
      type: "link",
      // badgeType: "light-primary",
      // badgeValue: "5",
      active: false,
    }
    ,
    {
      title: "Administrator",
      icon: "ui-kits",
      type: "sub",
      active: false,
      children: [
        { path: AppConst.MAIN_ROUTES.ADMINISTRATOR + "/" + AppConst.MAIN_ROUTES.USER_LIST, title: "User", type: "link" },
        { path: AppConst.MAIN_ROUTES.ADMINISTRATOR + "/" + AppConst.MAIN_ROUTES.ROLE_LIST, title: "Role", type: "link" },
        { path: AppConst.MAIN_ROUTES.ADMINISTRATOR + "/" + AppConst.MAIN_ROUTES.PERMISSION_LIST, title: "Permission List", type: "link" },
      ],
    }

  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  private unsubscriber: Subject<any> = new Subject();

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }
}
