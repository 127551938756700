import {Injectable} from '@angular/core';
import {BaseServiceService} from "./base-service.service";

@Injectable({
  providedIn: 'root'
})
export class ReferentialService extends BaseServiceService {
  constructor() {
    super();
  }

  getCountryList(): any {
    return this.apiGet("/country");
  }

}
