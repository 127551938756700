import {ChangeDetectorRef, Component, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {InvestorService} from '../../services/investor.service';
import {AppConst} from 'src/app/App.Const';
import {GlobalService} from '../../../shared/services/global.service'
import {TableService} from '../../services/table.service';
import {CandidateService} from '../../services/candidate.service';
import {OrderService} from '../../services/order.service';
import {ToastrService} from 'ngx-toastr';
import {BankTransactionService} from '../../services/bank-transaction.service';
import {BlockchainTransactionService} from "../../services/blockchain-transaction.service ";

declare var require
const Swal = require('sweetalert2')


@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss']
})
export class FilterTableComponent {
  investorstatus$: Observable<string[]>;
  invsetortype$: Observable<string[]>;
  invsetorCountry$: Observable<string[]>;
  transactionstatus$: Observable<string[]>;
  transactiontype$: Observable<string[]>;
  balanceComparator$: Observable<string[]>;
  recipients$: Observable<string[]>;
  candidateKYC$: Observable<string[]>;
  filterData: any = []
  date = new Date()
  investorObj: any = {
    status: null,
    type: null,
    countries: null,
    balance: {
      amount: null,
      comparator: null,
    },
    startDate: null,
    endDate: null,
  }
  transactionObj: any = {
    status: [],
    type: [],
    countries: [],
    recipients: [],
    investorType: "",
    balance: {
      amount: null,
      comparator: [],
    },
    startDate: null,
    endDate: null,
  }
  bankTransactionObj: any = {
    amount: {
      amount: null,
      comparator: [],
    },
    startDate: null,
    endDate: null,
  }
  CandidateObj: any = {
    status: [],
    type: [],
    countries: [],
    kyc: [],
    startDate: null,
    endDate: null,
  }
  OrderObj: any = {
    status: [],
    amountToPay: {
      amount: null,
      comparator: [],
    },
    startDate: null,
    endDate: null,
  }
  model: NgbDateStruct;
  displayMonths = 2;
  navigation = "select";
  showWeekNumbers = false;
  outsideDays = "visible";
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  model2: NgbDateStruct;
  placement = "top";
  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public eRef: ElementRef,
    public router: Router,
    public investorService: InvestorService,
    public TransactionService: BlockchainTransactionService,
    public globalService: GlobalService,
    public service: TableService,
    private cdr: ChangeDetectorRef,
    public candidateService: CandidateService,
    public OrderService: OrderService,
    public taostService: ToastrService,
    public bankTransactionService: BankTransactionService
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), "d", 10);

    this.investorstatus$ = of(["tokenholder", "inprogress", "qualified", "revoked", "failed"]);
    this.invsetortype$ = of(["individual", "institution"]);
    this.transactionstatus$ = of(["All", "Failed", "Pending", "Confirmed"]);
    this.transactiontype$ = of(["Transfer", "Mint", "Burn", "Block", "Unblock"]);
    this.invsetorCountry$ = of(["lie", "lux", "fra"]);
    this.balanceComparator$ = of(["EQUAL", "INFERIOR", "SUPERIOR"]);
    this.recipients$ = of(["Test Blochome", "Bobs Burgers"]);
    this.candidateKYC$ = of(["not_checked", "  approved"]);
  }
  ngOnInt() { }
  closeFilter() {
    let filter = document.getElementById("filter-table") as HTMLElement
    if (filter.style.right == "0%" || filter.style.right == "") {
      filter.style.right = "-100%"
    }
    else
      filter.style.right = "0%"
  }
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }
  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  Validate(): any {
    debugger
    if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.INVESTOR + "/" + AppConst.MAIN_ROUTES.INVESTOR_LIST)) {
      // if (this.investorObj.balance.comparator.length < 1) {
      //   return this.taostService.error('Balance Comparator is required')
      // }
    }
    else if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.TOKEN + "/" + AppConst.MAIN_ROUTES.TRANSACTION_LIST)) {
      if (this.transactionObj.balance.comparator.length < 1) {
        return this.taostService.error('Balance Comparator is required')
      }
    }
    else if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.TOKEN + "/" + AppConst.MAIN_ROUTES.BANK_TRANSACTION_LIST)) {
      if (this.bankTransactionObj.amount.comparator.length < 1) {
        return this.taostService.error('Balance Comparator is required')
      }
    }
    else if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.PRIMARY_MARKET + "/" + AppConst.MAIN_ROUTES.ORDER_LIST)) {
      if (this.OrderObj.amountToPay.comparator.length < 1) {
        return this.taostService.error('Balance Comparator is required')
      }
    }
  }
  FilterInvestorData() {
    this.investorService.getInvestorList(this.investorObj).subscribe({
      next: response => {
        console.log("Received", response);
        this.globalService.InvestortableItem$ = this.investorService.getInvestorList(this.investorObj)
        this.service._ChangedData$.subscribe(val => {
          this.globalService.InvestortableItem$ = this.service.tableItem$;
          this.globalService.Investortabletotal$ = this.service.total$;
          this.service.setUserData(response)
          this.service.setData()
          this.cdr.detectChanges();
        });
      },
      error: error => {
        console.log("unable to get investor list", error);
      }
    });
  }
  FilterTransactionData() {
    this.TransactionService.GetTransactionList(this.transactionObj).subscribe({
      next: response => {
        console.log("Received", response);
        this.globalService.TransactionTableItem$ = this.TransactionService.GetTransactionList(this.transactionObj)
        this.service._ChangedData$.subscribe(val => {
          this.globalService.TransactionTableItem$ = this.service.tableItem$;
          this.globalService.TransactionTabletotal$ = this.service.total$;
          this.service.setUserData(response)
          this.service.setData()
          this.cdr.detectChanges();
        });
      },
      error: error => {
        console.log("unable to get investor list", error);
      }
    });
  }
  FilterBankTransactionData() {
    this.bankTransactionService.GetBankTransactionList(this.bankTransactionObj).subscribe({
      next: response => {
        console.log("Received", response);
        this.globalService.BankTransactionTableItem$ = this.service.tableItem$;
        this.globalService.BankTransactionTabletotal$ = this.service.total$;
        this.service.setUserData(response)
        this.service.setData()
        this.cdr.detectChanges();
      },
      error: error => {
        console.log("unable to get bank transaction list", error);
      }
    });
  }
  FilterCandidateData() {
    this.candidateService.getCandidateList(this.CandidateObj).subscribe({
      next: response => {
        console.log("Received", response);
        this.globalService.CandidateTableItem$ = this.candidateService.getCandidateList(this.CandidateObj)
        this.service._ChangedData$.subscribe(val => {
          this.globalService.CandidateTableItem$ = this.service.tableItem$;
          this.globalService.CandidateTabletotal$ = this.service.total$;
          this.service.setUserData(response)
          this.service.setData()
          this.cdr.detectChanges();
        });
      },
      error: error => {
        console.log("unable to get candidate list", error);
      }
    });
  }
  FilterOrderData() {
    this.OrderService.getOrderList(this.OrderObj).subscribe({
      next: response => {
        console.log("Received", response);
        this.globalService.OrderTableItem$ = this.OrderService.getOrderList(this.OrderObj)
        this.service._ChangedData$.subscribe(val => {
          this.globalService.OrderTableItem$ = this.service.tableItem$;
          this.globalService.OrderTabletotal$ = this.service.total$;
          this.service.setUserData(response)
          this.service.setData()
          this.cdr.detectChanges();
        });
      },
      error: error => {
        console.log("unable to get order list", error);
      }
    });
  }
  ResetInvestorData() {
    this.investorService.getInvestorList({}).subscribe({
      next: response => {
        this.globalService.InvestortableItem$ = this.service.tableItem$;
        this.globalService.Investortabletotal$ = this.service.total$;
        this.service.setUserData(response)
        this.service.setData()
        this.cdr.detectChanges();
      },
      error: error => {
        console.log("unable to get investor list", error);
      }
    });
  }
  ResetTransactionData() {
    this.TransactionService.GetTransactionList({}).subscribe({
      next: response => {
        this.service._ChangedData$.subscribe(val => {
          this.globalService.TransactionTableItem$ = this.service.tableItem$;
          this.globalService.TransactionTabletotal$ = this.service.total$;
          this.service.setUserData(response)
          this.service.setData()
          this.cdr.detectChanges();
        });
      },
      error: error => {
        console.log("unable to get transaction list", error);
      }
    });
  }
  ResetBankTransactionData() {
    this.bankTransactionService.GetBankTransactionList({}).subscribe({
      next: response => {
        this.globalService.BankTransactionTableItem$ = this.service.tableItem$;
        this.globalService.BankTransactionTabletotal$ = this.service.total$;
        this.service.setUserData(response)
        this.service.setData()
        this.cdr.detectChanges();
      },
      error: error => {
        console.log("unable to get bank transaction list", error);
      }
    });
  }
  ResetCandidateData() {
    this.candidateService.getCandidateList({}).subscribe({
      next: response => {
        console.log(response)
        this.globalService.CandidateTableItem$ = this.service.tableItem$;
        this.globalService.CandidateTabletotal$ = this.service.total$;
        this.service.setUserData(response)
        this.service.setData()
        this.cdr.detectChanges();
      },
      error: error => {
        console.log("unable to get Candidate list", error);
      }
    });
  }
  ResetOrderData() {
    this.OrderService.getOrderList({}).subscribe({
      next: response => {
        this.globalService.OrderTableItem$ = this.service.tableItem$;
        this.globalService.OrderTabletotal$ = this.service.total$;
        this.service.setUserData(response)
        this.service.setData()
        this.cdr.detectChanges();
      },
      error: error => {
        console.log("unable to get order list", error);
      }
    });
  }
  FilterData() {
    if (this.Validate()) {
      return false
    }
    else {
      if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.INVESTOR + "/" + AppConst.MAIN_ROUTES.INVESTOR_LIST)) {
        this.FilterInvestorData()
      }
      else if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.TOKEN + "/" + AppConst.MAIN_ROUTES.TRANSACTION_LIST)) {
        this.FilterTransactionData()
      }
      else if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.TOKEN + "/" + AppConst.MAIN_ROUTES.BANK_TRANSACTION_LIST)) {
        this.FilterBankTransactionData()
      }
      else if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.INVESTOR + "/" + AppConst.MAIN_ROUTES.CANDIDATES_LIST)) {
        this.FilterCandidateData()
      }
      else if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.PRIMARY_MARKET + "/" + AppConst.MAIN_ROUTES.ORDER_LIST)) {
        this.FilterOrderData()
      }
      this.closeFilter()
    }
    return true
  }
  ResetData() {
    if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.INVESTOR + "/" + AppConst.MAIN_ROUTES.INVESTOR_LIST)) {
      this.investorObj.status = null
      this.investorObj.type = null
      this.investorObj.countries = null
      this.investorObj.balance.amount = null
      this.investorObj.balance.comparator = null
      this.investorObj.startDate = null
      this.investorObj.endDate = null
      this.ResetInvestorData()
    }
    else if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.TOKEN + "/" + AppConst.MAIN_ROUTES.TRANSACTION_LIST)) {
      this.transactionObj.status = []
      this.transactionObj.type = []
      this.transactionObj.countries = []
      this.transactionObj.investorType = ""
      this.transactionObj.recipients = []
      this.transactionObj.balance.amount = null
      this.transactionObj.balance.comparator = []
      this.transactionObj.startDate = null
      this.transactionObj.endDate = null
      this.ResetTransactionData()
    }
    else if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.TOKEN + "/" + AppConst.MAIN_ROUTES.BANK_TRANSACTION_LIST)) {
      this.bankTransactionObj.amount.amount = null
      this.bankTransactionObj.amount.comparator = []
      this.bankTransactionObj.startDate = null
      this.bankTransactionObj.endDate = null
      this.ResetBankTransactionData()
    }
    else if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.INVESTOR + "/" + AppConst.MAIN_ROUTES.CANDIDATES_LIST)) {
      this.CandidateObj.status = []
      this.CandidateObj.type = []
      this.CandidateObj.countries = []
      this.CandidateObj.kyc = ""
      this.CandidateObj.startDate = null
      this.CandidateObj.endDate = null
      this.ResetCandidateData()
    }
    else if (window.location.pathname === ("/" + AppConst.MAIN_ROUTES.PRIMARY_MARKET + "/" + AppConst.MAIN_ROUTES.ORDER_LIST)) {
      this.OrderObj.status = []
      this.OrderObj.amountToPay.amount = null
      this.OrderObj.amountToPay.comparator = []
      this.OrderObj.startDate = null
      this.OrderObj.endDate = null
      this.ResetOrderData()
    }
    this.closeFilter()
  }
}
