import {Injectable} from '@angular/core';
import {BaseServiceService} from './base-service.service';

@Injectable({
  providedIn: 'root'
})

export class BlockchainTransactionService extends BaseServiceService {

  constructor() {
    super();
  }

  GetTransactionList(params: any): any {
    return this.apiPost("/transaction/" + this.selectedWorkspace, params);
  }

  getIssuerTransactions(investorWallet:string): any {
    return this.apiGet( "/transaction/" + this.selectedWorkspace + "/investor/" + investorWallet);
  }

}
