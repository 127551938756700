import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public InvestortableItem$: any = [];
  Investortabletotal$: Observable<number>;

  public TransactionTableItem$: any = [];
  TransactionTabletotal$: Observable<number>;

  public CandidateTableItem$: any = [];
  CandidateTabletotal$: Observable<number>;

  public OrderTableItem$: any = [];
  OrderTabletotal$: Observable<number>;

  public RoleTableItem$: any = [];
  RoleTabletotal$: Observable<number>;

  public PermissionTableItem$: any = [];
  PermissionTabletotal$: Observable<number>;

  public UserTableItem$: any = [];
  UserTableTotal$: Observable<number>;

  public BankTransactionTableItem$: any = [];
  BankTransactionTabletotal$: Observable<number>;

  public PageSizeArray = [
    {
      key: "10",
      value: "10"
    },
    {
      key: "20",
      value: "20"
    },
    {
      key: "50",
      value: "50"
    },
    {
      key: "100",
      value: "100"
    }
  ]

  constructor() { }
  SetDateFormat(date: any) {
    return new Date(date).toLocaleDateString('en-US');
  }
  SetDateFormatTime(date: any) {
    return new Date(date).toLocaleString('en-US');
  }
}
