export class AppConst {
  static MAIN_ROUTES = {
    // ### DASHBOARD #####
    DASHBOARD: "dashboard",
    // ### ADMINISTRATION #####
    ADMINISTRATOR: "administrator",
    USER_LIST: "user-list",
    MAINTAIN_USER: "maintain-user",
    ROLE_LIST: "role-list",
    MAINTAIN_ROLE: "maintain-role",
    PERMISSION_LIST: "permission-list",

    // ### TRANSACTION #####
    TRANSACTION_LIST: "transaction-list",
    CANDIDATES_LIST: "candidate-list",
    CANDIDATES: "candidate",
    TRANSACTION: "transaction",
    ORDER_LIST: "order-list",
    BANK_TRANSACTION_LIST: "bank-transaction-list",
    ORDER: "order",
    BLOCKCHAIN: "blockchain",
    BANK: "bank",
    // ### INVESTOR #####
    INVESTOR: "investor",
    INVESTOR_WARNINGS: "warnings",
    INVESTORS: "investors",
    INVESTOR_LIST: "investor-list",
    COMPLIANCE: "compliance",
    COMPLIANCE_DASHBOARD: "compliance-dashboard",


    // ### AUTH #####
    LOGIN: "login",
    EMAIL_VERIFICATION: "email-verification",

    // ### OTHER #####
    TOKEN: "",
    PRIMARY_MARKET: "",

    // ### ACTIVITIES #####
    ACTIVITIES: "activities",
    ACTIVITY_LIST: "",
    ACTIVITY_VIEW: ""


  }
  static URL_REQUEST = {
    LOGIN: "Account/v1/Login",
    GET_INVESTOR_LIST: "investor/",
    FILTER_INVESTOR_LIST : "investor/",
    GET_TRANSACTION_LIST: "transaction/",
    FILTER_TRANSACTION_LIST : "transaction/",
    GET_CANDIDATE_LIST: "transaction/",
    FILTER_CANDIDATE_LIST : "transaction/",
  }
}
