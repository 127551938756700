import {Routes} from "@angular/router";
import {AppConst} from "src/app/App.Const";
import {AdminGuard} from "../guard/admin.guard";

export const content: Routes = [
  {
    path: AppConst.MAIN_ROUTES.INVESTORS,
    loadChildren: () => import('../../module/investors/investors.module').then(m => m.InvestorsModule),
  },
  {
    path: AppConst.MAIN_ROUTES.TRANSACTION,
    loadChildren: () => import('../../module/transactions/transactions.module').then(m => m.TransactionsModule),
    canActivate:[AdminGuard]
  },
  {
    path: AppConst.MAIN_ROUTES.ADMINISTRATOR,
    loadChildren: () => import('../../module/administrator/administrator.module').then(m => m.AdministratorModule),
    canActivate:[AdminGuard]
  },
  {
    path: AppConst.MAIN_ROUTES.ACTIVITIES,
    loadChildren: () => import('../../module/activities/activities.module').then(m => m.ActivitiesModule),
    canActivate:[AdminGuard]
  }
];
