import {Injectable} from '@angular/core';
import {BaseServiceService} from './base-service.service';

@Injectable({
  providedIn: 'root'
})

export class BankTransactionService  extends BaseServiceService {

  constructor() {
    super();
  }

  GetBankTransactionList(params: any): any {
    return this.apiPost("/banktransaction/", params);
  }

  getInvestorBankTransactionList(tokenId:string)
  {
    return this.apiGet("/banktransaction/"+ this.selectedWorkspace + "/investor/" + tokenId);
  }


}
