import {Component, Inject, PLATFORM_ID} from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {delay, map, withLatestFrom} from 'rxjs/operators';
import {SupabaseService} from "./shared/services/supabase.service";
import {PrimeNGConfig} from "primeng/api";

// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  session = this.supabase.session;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private readonly supabase: SupabaseService,
              private primengConfig: PrimeNGConfig) {
    // if (isPlatformBrowser(this.platformId)) {
    //   translate.setDefaultLang('en');
    //   translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    // }
    //this.supabase.onAuthStateChange();



  }

  ngOnInit() {
    this.supabase.authChanges((_, session) => (this.session = session))
    this.primengConfig.ripple = true;
  }

}
