<div class="candidate-detail">
  <div class="">
    <h6>Profile</h6>
    <div class="row  ps-3 pe-3 align-items-center">
      <div class="col-lg-3 col-md-3 col-sm-6">
        <div id="circlechart">
          <apx-chart [chart]="radial.chart" [colors]="radial.colors"
                     [labels]="radial.labels" [plotOptions]="radial.plotOptions"
                     [series]="radial.pieseries"></apx-chart>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Type</label>
        <p class="detail-body"><i class="icofont icofont-ui-user" style="color: #7366FF;"></i>&nbsp;
          {{detailCandidate?.investorType}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Current section</label>
        <p class="detail-body">
          {{detailCandidate?.currentSection}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Last login</label>
        <p class="detail-body">
          {{detailCandidate?.lastLogin | date : 'short'}}</p>
      </div>
    </div>
  </div>

  <div class="">
    <h6>Qualification</h6>
    <div class="row  ps-3 pe-3 align-items-center">
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Status</label><br>
        <span class="badge badge-light-primary">
                                <i class="icofont icofont-address-book"></i>&nbsp;
          {{detailCandidate?.status}}
                            </span>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">KYC status</label><br>
        <span class="badge badge-light" style="color: black;">
                                <i class="icofont icofont-tick-mark" style="color: #7366FF;"></i>&nbsp;
          {{detailCandidate?.kycStatus}}
                            </span>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6"></div>
      <div class="col-lg-3 col-md-3 col-sm-6"></div>
    </div>
  </div>

  <div *ngIf="detailCandidate?.mainData?.identity" class="">
    <h6>Identity</h6>
    <div class="row  ps-3 pe-3 align-items-center">
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">First name</label>
        <p class="detail-body">{{detailCandidate?.mainData?.identity?.firstName?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Last name</label>
        <p class="detail-body">{{detailCandidate?.mainData?.identity?.lastName?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Gender</label>
        <p class="detail-body">{{detailCandidate?.mainData?.identity?.gender?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Nationality</label>
        <p class="detail-body">{{detailCandidate?.mainData?.identity?.nationality?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Birthday</label>
        <p class="detail-body">{{detailCandidate?.mainData?.identity?.birthday?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Birth place</label>
        <p class="detail-body">{{detailCandidate?.mainData?.identity?.birthplace?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Identification Document</label>
        <p class="detail-body">{{detailCandidate?.mainData?.identity?.idDocument?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Passport Number</label>
        <p class="detail-body">{{detailCandidate?.mainData?.identity?.passportNumber?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">ID Card/Passport expiration</label>
        <p class="detail-body">-</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Phone number</label>
        <p class="detail-body">{{detailCandidate?.mainData?.identity?.phone?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Occupation / Sector of activity</label>
        <p class="detail-body">{{detailCandidate?.mainData?.identity?.occupation?.value}}</p>
      </div>
    </div>
  </div>

  <div *ngIf="detailCandidate?.mainData?.company" class="">
    <h6>Company</h6>
    <div class="row  ps-3 pe-3 align-items-center">
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">{{detailCandidate?.mainData?.company?.institutionTradeName?.label}}</label>
        <p class="detail-body">{{detailCandidate?.mainData?.company?.institutionTradeName?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">{{detailCandidate?.mainData?.company?.institutionLegalName?.label}}</label>
        <p class="detail-body">{{detailCandidate?.mainData?.company?.institutionLegalName?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">{{detailCandidate?.mainData?.company?.institutionRegistryNumber?.label}}</label>
        <p class="detail-body">{{detailCandidate?.mainData?.company?.institutionRegistryNumber?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">{{detailCandidate?.mainData?.company?.institutionRegistryNumber?.label}}</label>
        <p class="detail-body">{{detailCandidate?.mainData?.company?.institutionRegistryNumber?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">{{detailCandidate?.mainData?.company?.institutionLegalForm?.label}}</label>
        <p class="detail-body">{{detailCandidate?.mainData?.company?.institutionLegalForm?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">{{detailCandidate?.mainData?.company?.institutionIncorporationDate?.label}}</label>
        <p class="detail-body">{{detailCandidate?.mainData?.company?.institutionIncorporationDate?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">{{detailCandidate?.mainData?.company?.institutionRegistryCountry?.label}}</label>
        <p class="detail-body">{{detailCandidate?.mainData?.company?.institutionRegistryCountry?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">{{detailCandidate?.mainData?.company?.institutionActivity?.label}}</label>
        <p class="detail-body">{{detailCandidate?.mainData?.company?.institutionActivity?.value}}</p>
      </div>

    </div>
  </div>

  <div *ngIf="detailCandidate?.mainData?.company" class="">
    <h6>Company representative</h6>
    <div class="row  ps-3 pe-3 align-items-center">
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">First name</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.institutionAdminFirstName?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Last name</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.institutionAdminLastName?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Gender</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.institutionAdminGender?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Nationality</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.institutionAdminNationality?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Birthday</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.institutionAdminBirthday?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Birth place</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.birthplace?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Identification Document</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.institutionAdminIdDocument?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Passport Number</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.passportNumber?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">ID Card/Passport expiration</label>
        <p class="detail-body">-</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Phone number</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.institutionAdminPhone?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Email</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.institutionAdminEmail?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Occupation / Sector of activity</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.institutionAdminPosition?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">{{detailCandidate?.mainData?.companyRepresentative?.legalRepresentativeCheck?.label}}</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyRepresentative?.legalRepresentativeCheck?.value}}</p>
      </div>
    </div>
  </div>

  <div class="">
    <h6>Politically exposed</h6>
    <div class="row  ps-3 pe-3 align-items-center">
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">If you are a politically exposed person please check
          here</label>
        <p class="detail-body"><i class="icofont icofont-ui-user" style="color: #7366FF;"></i>&nbsp;
          {{detailCandidate?.mainData?.politicallyExposed?.exposed?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6"></div>
      <div class="col-lg-3 col-md-3 col-sm-6"></div>
      <div class="col-lg-3 col-md-3 col-sm-6"></div>
    </div>
  </div>

  <div *ngIf="detailCandidate?.mainData?.company" class="">
    <h6>Ultimate beneficial owner s</h6>
    <div class="row  ps-3 pe-3 align-items-center">
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">{{detailCandidate?.mainData?.ultimateBeneficialOwnerS?.uboNumber?.label}}</label>
        <p class="detail-body">
          {{detailCandidate?.mainData?.ultimateBeneficialOwnerS?.uboNumber?.value}}
        </p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">{{detailCandidate?.mainData?.ultimateBeneficialOwnerS?.uboPoliticallyExposed?.label}}</label>
        <p class="detail-body">
          {{detailCandidate?.mainData?.ultimateBeneficialOwnerS?.uboPoliticallyExposed?.value}}
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="detailCandidate?.mainData?.identity" class="">
    <h6>Residency address</h6>
    <div class="row  ps-3 pe-3 align-items-center">
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Building number</label>
        <p class="detail-body">
          {{detailCandidate?.mainData?.residencyAddress?.buildingNumber?.value}}
        </p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Street</label>
        <p class="detail-body">{{detailCandidate?.mainData?.residencyAddress?.street?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">City</label>
        <p class="detail-body">{{detailCandidate?.mainData?.residencyAddress?.city?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">State</label>
        <p class="detail-body">{{detailCandidate?.mainData?.residencyAddress?.state?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Country</label>
        <p class="detail-body">{{detailCandidate?.mainData?.residencyAddress?.country?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Zip code</label>
        <p class="detail-body">{{detailCandidate?.mainData?.residencyAddress?.zipCode?.value}}</p>
      </div>
    </div>
  </div>

  <div *ngIf="detailCandidate?.mainData?.company" class="">
    <h6>Company legal address</h6>
    <div class="row  ps-3 pe-3 align-items-center">
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Building number</label>
        <p class="detail-body">
          {{detailCandidate?.mainData?.companyLegalAddress?.institutionBuildingNumber?.value}}
        </p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Street</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyLegalAddress?.institutionStreet?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">City</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyLegalAddress?.institutionCity?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">State</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyLegalAddress?.institutionState?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Country</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyLegalAddress?.institutionCountry?.value}}</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6">
        <label class="detail-heading">Zip code</label>
        <p class="detail-body">{{detailCandidate?.mainData?.companyLegalAddress?.institutionZipCode?.value}}</p>
      </div>
    </div>
  </div>
  <h6>Investment information</h6>
  <div class="row  ps-3 pe-3 align-items-center">
    <div class="col-lg-3 col-md-3 col-sm-6">
      <label class="detail-heading">Source of funds</label>
      <p class="detail-body">
        {{detailCandidate?.mainData?.investmentInformation?.sourceOfFunds?.value}}</p>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6">
      <label class="detail-heading">Intended investment</label>
      <p class="detail-body">
        {{detailCandidate?.mainData?.investmentInformation?.investmentThreshold?.value}}</p>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6">
      <label class="detail-heading">Intended investment amount (EUR)</label>
      <p class="detail-body">
        {{detailCandidate?.mainData?.investmentInformation?.investmentAmount?.value}}</p>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6">
      <label class="detail-heading">Tax Identification Number</label>
      <p class="detail-body">{{detailCandidate?.mainData?.investmentInformation?.tin?.value}}</p>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6">
      <label class="detail-heading">Tax automatic certification</label>
      <p class="detail-body">
        {{detailCandidate?.mainData?.investmentInformation?.taxAutoCheck?.value}}
      </p>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6">
      <label class="detail-heading">Annual gross revenue</label>
      <p class="detail-body">
        {{detailCandidate?.mainData?.investmentInformation?.annualGrossRevenue?.value}}</p>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6">
      <label class="detail-heading">Referral code</label>
      <p class="detail-body">
        {{detailCandidate?.mainData?.investmentInformation?.referralCode?.value}}
      </p>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6">
      <label class="detail-heading">Monthly payments</label>
      <p class="detail-body">
        {{detailCandidate?.mainData?.investmentInformation?.monthlyPayments?.value}}</p>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6">
      <label class="detail-heading">Where did you hear about us?</label>
      <p class="detail-body">
        {{detailCandidate?.mainData?.investmentInformation?.whereDidYouHearAboutUs?.value}}</p>
    </div>
  </div>
  <h6>Documents</h6>
  <div class="document_file">
    <div (click)="displayImage(item)" *ngFor="let item of detailCandidate.documents" class="document">
      <img alt="" src="../../../../assets/images/document-icon.png">
      <label for="">{{item.label}}</label>
    </div>
  </div>
  <h6>Wallet address</h6>
  <div class="row  ps-3 pe-3 align-items-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <p class="detail-heading"><span style="color: #7366FF;">ONCHAINID </span><u> WALLET ADDRESS
        PENDING</u>
      </p>
    </div>
  </div>
</div>
