import {Injectable} from '@angular/core';
import {BaseServiceService} from "./base-service.service";

@Injectable({
  providedIn: 'root'
})
export class CandidateService extends BaseServiceService {
  constructor() {
    super();
  }

  getCandidateList(params: any): any {
    return this.apiPost("/candidate/" + this.selectedWorkspace, params);
  }

  getCandidateDocument(params: any): any {
    return this.apiGet("/candidate/" + this.selectedWorkspace+ "/" + params.id+ "/" + params.documentId);
  }

  getCandidateDataById(params: any): any {
    return this.apiGet("/candidate/" + this.selectedWorkspace + "/" + params.id);
  }

}
