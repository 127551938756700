import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {SupabaseService} from "./supabase.service";
import {AppInjector} from "../../app-injector.service";
import {take} from "rxjs";

export abstract class BaseServiceService {
  protected httpClient: HttpClient;
  protected supabase: SupabaseService;
  protected currentUser: any;
  protected token: string;

  protected apiUrl = environment.apiUrl;
  protected selectedWorkspace = environment.selectedWorkspace;
  constructor() {

    AppInjector.getInjector().pipe(take(1)).subscribe((injector) => {
      this.httpClient = injector.get(HttpClient);
      this.supabase = injector.get(SupabaseService);
    });
   // this.initToken();
  }

  setTokenValue(token: string) {
    this.token = token;
  }

  getTokenHeader() {
    if (this.token === null || this.token === undefined)
    {
        let supabaseToken = localStorage.getItem("sb-"+environment.supabaseId+ "-auth-token");
      debugger;
        if (supabaseToken != null)
        {
          this.token = JSON.parse(supabaseToken).access_token;
          console.log("token set from local storage")
        }

    }
    return new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    }).append('Access-Control-Allow-Origin', '*');
  }

  apiGet(endpointUrl: String): any {
    let token = this.getTokenHeader();
    console.log("TOKEN------>",token);
    return this.httpClient.get<any>(this.apiUrl + endpointUrl, { headers: token });
  }

  apiGetWithBody(endpointUrl: String, param: any): any {
    var httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + this.token).set('Access-Control-Allow-Origin', '*'),
      body: param,
    }
    return this.httpClient.get<any>(this.apiUrl + endpointUrl, httpOptions);
  }

  apiPost(endpointUrl: String, postBody: any): any {
    let token = this.getTokenHeader();

    console.log(token);
    return this.httpClient.post<any>(this.apiUrl + endpointUrl, postBody, { headers: token });
  }

  apiPost_WithoutBody(endpointUrl: String, params: any): any {
    let token = this.getTokenHeader();

    console.log(token);
    return this.httpClient.post<any>(this.apiUrl + endpointUrl, { userId: params }, { headers: token });
  }

  initToken(data): Promise<void> {
    this.token = data?.session?.access_token;
    this.currentUser = data?.user;
    return Promise.resolve();
  }
  // initToken(): void {
  //   let supabaseToken = localStorage.getItem("sb-rcxsqczjgozjwyfqtqem-auth-token");
  //   if (supabaseToken != null)
  //     this.token = JSON.parse(supabaseToken).access_token;
  //   this.supabase.client.auth.getSession().then(({ data }) => {
  //     if (data.session) {
  //       this.token = this.supabase?.session?.access_token;
  //       this.currentUser = this.supabase?.session?.user;
  //     }
  //     else {
  //       this.supabase.client.auth.getUser().then(({ data, error }) => {
  //         if (data.user) {
  //           this.token = this.supabase?.session?.access_token;
  //           this.currentUser = data.user;
  //         }
  //       })
  //     }
  //   });
//}

}
