import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent {
  is_block = false
  is_unblock = false
  is_delete = false
  message: any = ''
  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) { }

  close(confirm: any) {
    this.activeModal.close(confirm)
  }
}
