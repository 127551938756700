<div class="card">
    <div class="alert-image">
        <img *ngIf="is_unblock == true" src="assets/images/block.png" class="img-fluid" alt="block-image">
        <img *ngIf="is_block == true" src="assets/images/unblock.png" class="img-fluid" alt="unblock-image">
        <img *ngIf="is_delete == true" src="assets/images/delete.png" class="img-fluid" alt="delete-image">
    </div>
    <div class="card-body">
        <h5>{{message}}</h5>
    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-outline-primary" (click)="close('no')">Canel</button>
        <button type="button" class="btn btn-primary" autofocus (click)="close('success')">Save</button>
    </div>
</div>