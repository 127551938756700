import {Injectable} from '@angular/core'
import {AuthChangeEvent, AuthSession, createClient, Session, SupabaseClient,} from '@supabase/supabase-js'
import {environment} from 'src/environments/environment'
import {Router} from "@angular/router";


@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private supabase: SupabaseClient
  constructor(private router: Router) {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey)
  }

  _session: AuthSession | null = null

  get session() {
    this.supabase.auth.getSession().then(({ data }) => {
      this._session = data.session;
    })
    return this._session
  }

  get client()
  {
    return this.supabase;
  }

  get accessToken() {
    return this._session?.access_token;
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback)
  }


  signIn(email: string,password:string) {
    //return this.supabase.auth.signInWithOtp({email, options:{emailRedirectTo:window.location.origin}})
    return this.supabase.auth.signInWithPassword({email,password})
  }

  signOut() {
    this._session = null;
    localStorage.removeItem("userBackend");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("sb-"+environment.supabaseId+ "-token");
    return this.supabase.auth.signOut()
  }

  onAuthStateChange() {
    this.supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('event: ', event);
      console.log('session: ', session);

      if (event === 'SIGNED_IN') {
        // this._currentUser.next(session.user);
        // this.handleUserDataChange();
      } else if (event === 'USER_UPDATED') {
        // this._currentUser.next(session.user);
      } else {
        // this._currentUser.next(false);
      }
    });
  }

  signInWithEmail() {
   return this.supabase.auth.signInWithPassword({
      email: 'example@email.com',
      password: 'example-password',
    })
  }
  logOutAll()
  {
    this.signOut().then(r =>
    {
      localStorage.removeItem("userBackend");
      localStorage.removeItem("sb-"+environment.supabaseId+ "-auth-token");
      this.router.navigateByUrl('auth/login');
    });
  }
}
