import {Injectable} from '@angular/core';
import {BaseServiceService} from "./base-service.service";

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseServiceService {
  constructor() {
    super();
  }

  getOrderList(params: any): any {
    return this.apiPost("/order/" + this.selectedWorkspace, params);
  }

  getOrderDataById(params: any): any {
    return this.apiGet("/order/" + this.selectedWorkspace + "/" + params.id);
  }

  getInvestorOrderList(tokenId:string)
  {
    return this.apiGet("/order/"+ this.selectedWorkspace + "/investor/" + tokenId);
  }
}
