<!-- <div class="container">
    <div class="email-verification">
        <div id="msg" class="msg">
            <div class="msg-show">
                <img data-animation="fadeIn" data-animation-delay="0" data-animation-duration="1000" class="img-fluid"
                    src="assets\images\login\submit.png" alt="">
                <h4>Check your email. Login link has been send to yor mailbox.</h4>
            </div>
        </div>
    </div>
</div> -->
<div class="container-fluid p-0">
    <div class="row g-0">
        <div class="col-12">
            <div class="login-card">
                <div>
                    <div>
                        <a class="logo" routerLink="/">
                            <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage" />
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" /> -->
                            <h2>LetzToken</h2>
                        </a>
                    </div>
                    <div class="login-main">
                        <div id="msg" class="msg">
                            <div class="msg-show">
                                <img class="img-fluid" src="assets\images\login\submit.png" alt="">
                                <h4>Check your email. Login link has been send to your mailbox.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>