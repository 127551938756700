<!--Filter Table-->
<div class="filter-table" id="filter-table">
    <div class="filter-card" id="filter-card">
        <div class="filter-header">
            <h5>Filter</h5>
            <b (click)="closeFilter()" class="btn-primary">X</b>
        </div>
        <div class="filter-body">

            <form *ngIf="this.router.url == '/token/transaction-list'" class="transaction-form">
                <div class="mb-3">
                    <label for="exampleFormControlSelect3">Status</label>
                    <ng-select [(ngModel)]="transactionObj.status" [items]="transactionstatus$ | async" [multiple]="true"
                        name="status" placeholder="Select"> </ng-select>
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlSelect3">Type</label>
                    <ng-select [(ngModel)]="transactionObj.type" [items]="transactiontype$ | async" [multiple]="true"
                        name="type" placeholder="Select"> </ng-select>
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlSelect3">Countries</label>
                    <ng-select [(ngModel)]="transactionObj.countries" [items]="invsetorCountry$ | async"
                        [multiple]="true" name="countries" placeholder="Select"> </ng-select>
                </div>
                <div class="mb-3 ">
                    <!-- <div class="d-flex justify-content-between align-items-center"> -->
                    <label class="col-form-label">Creation date between</label>
                    <!-- <label class="col-form-label">End Date</label> -->
                    <!-- </div> -->
                    <div class="row g-3">
                        <div class="col-xl-12 col-sm-12">
                            <div class="digits">
                                <form class="row">
                                    <div class="col-6 mb-1">
                                        <div class="dp-hidden position-absolute">
                                            <div class="input-group">
                                                <input #datepicker="ngbDatepicker" (dateSelect)="onDateSelection($event)"
                                                    [autoClose]="'outside'" [dayTemplate]="t" [displayMonths]="2"
                                                    [startDate]="fromDate!" class="form-control datepicker-here border-0"
                                                    name="datepicker" ngbDatepicker outsideDays="hidden"
                                                    tabindex="-1" />
                                                <ng-template #t let-date let-focused="focused">
                                                    <span (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
                                                        [class.faded]="isHovered(date) || isInside(date)"
                                                        [class.focused]="focused"
                                                        [class.range]="isRange(date)"
                                                        class="custom-day">
                                                        {{ date.day }}
                                                    </span>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="input-group">
                                            <input #dpFromDate (click)="datepicker.toggle()"
                                                (input)="fromDate = validateInput(fromDate, dpFromDate.value)" [(ngModel)]="transactionObj.startDate"
                                                [value]="formatter.format(fromDate)" class="form-control datepicker-here"
                                                name="startDate"
                                                placeholder="yyyy-mm-dd" />
                                        </div>
                                    </div>
                                    <div class="col-6 mb-1">
                                        <div class="input-group">
                                            <input #dpToDate (click)="datepicker.toggle()"
                                                (input)="toDate = validateInput(toDate, dpToDate.value)" [(ngModel)]="transactionObj.endDate"
                                                [value]="formatter.format(toDate)" class="form-control datepicker-here"
                                                name="endDate"
                                                placeholder="yyyy-mm-dd" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="col-form-label" for="">Balance</label>
                    <div class="d-flex justify-content-between align-content-center">
                        <ng-select [(ngModel)]="transactionObj.balance.comparator" [items]="balanceComparator$ | async"
                            name="status" placeholder="Select" style="width: 40%;"> </ng-select>
                        <input [(ngModel)]="transactionObj.balance.amount" class="form-control ms-2" id=""
                            name="balance" placeholder="" style="width: 60%;" type="number">
                    </div>
                </div>
                <div class="mb-3">
                    <label class="col-form-label" for="">Investor Type</label>
                    <input [(ngModel)]="transactionObj.investorType" class="form-control" id="" name="investorType" placeholder=""
                        type="text">
                </div>
                <div class="mb-3">
                    <label class="col-form-label" for="inputEmail3">Recipient</label>
                    <ng-select [(ngModel)]="transactionObj.recipients" [items]="recipients$ | async" [multiple]="true"
                        name="recipients" placeholder="Select"> </ng-select>
                </div>
            </form>
            <form *ngIf="this.router.url == '/primary-market/order-list'" class="order-form">
                <div class="mb-3">
                    <label for="exampleFormControlSelect3">Status</label>
                    <ng-select [(ngModel)]="OrderObj.status" [items]="investorstatus$ | async" [multiple]="true"
                        name="status" placeholder="Select"> </ng-select>
                </div>
                <div class="mb-3 ">
                    <label class="col-form-label">Creation date between</label>
                    <div class="row g-3">
                        <div class="col-xl-12 col-sm-12">
                            <div class="digits">
                                <form class="row">
                                    <div class="col-6 mb-1">
                                        <div class="dp-hidden position-absolute">
                                            <div class="input-group">
                                                <input #datepicker="ngbDatepicker" (dateSelect)="onDateSelection($event)"
                                                    [autoClose]="'outside'" [dayTemplate]="t" [displayMonths]="2"
                                                    [startDate]="fromDate!" class="form-control datepicker-here border-0"
                                                    name="datepicker" ngbDatepicker outsideDays="hidden"
                                                    tabindex="-1" />
                                                <ng-template #t let-date let-focused="focused">
                                                    <span (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
                                                        [class.faded]="isHovered(date) || isInside(date)"
                                                        [class.focused]="focused"
                                                        [class.range]="isRange(date)"
                                                        class="custom-day">
                                                        {{ date.day }}
                                                    </span>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="input-group">
                                            <input #dpFromDate (click)="datepicker.toggle()"
                                                (input)="fromDate = validateInput(fromDate, dpFromDate.value)" [(ngModel)]="OrderObj.startDate"
                                                [value]="formatter.format(fromDate)" class="form-control datepicker-here"
                                                name="startDate"
                                                placeholder="yyyy-mm-dd" />
                                        </div>
                                    </div>
                                    <div class="col-6 mb-1">
                                        <div class="input-group">
                                            <input #dpToDate (click)="datepicker.toggle()"
                                                (input)="toDate = validateInput(toDate, dpToDate.value)" [(ngModel)]="OrderObj.endDate"
                                                [value]="formatter.format(toDate)" class="form-control datepicker-here"
                                                name="endDate"
                                                placeholder="yyyy-mm-dd" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="col-form-label" for="">Amount to pay</label>
                    <div class="d-flex justify-content-between align-content-center">
                        <ng-select [(ngModel)]="OrderObj.amountToPay.comparator" [items]="balanceComparator$ | async"
                            name="status" placeholder="Select" style="width: 40%;"> </ng-select>
                        <input [(ngModel)]="OrderObj.amountToPay.amount" class="form-control ms-2" id="" name="balance"
                            placeholder="" style="width: 60%;" type="number">
                    </div>
                </div>
            </form>

        </div>
        <div class="filter-footer text-center">
            <button (click)="ResetData()" class="btn btn-primary me-1">Reset</button>
            <button (click)="FilterData()" class="btn btn-secondary">Apply Filter</button>
        </div>
    </div>
</div>
