import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Component, OnInit} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {saveAs} from 'file-saver';

@Component({
  selector: "image-download",
  templateUrl: "./image-viewer.component.html",
  styleUrls: ["./image-viewer.component.scss"],
})
export class ImageViewerComponent implements OnInit {

  public data;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private http: HttpClient, private _sanitizer: DomSanitizer) {
    this.data = this.config.data;
  }



  ngOnInit() {

  }

  downloadSimple(): void {
    saveAs(this.data.url, this.data.filename);
  }

  downloadImage(url: string): void {
    var image: any = this._sanitizer.bypassSecurityTrustResourceUrl(url);
    this.http.get(url, { responseType: 'blob',headers:  new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
      })})
      .subscribe((response: Blob) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(response);
        a.href = objectUrl;
        if (image)
          a.download = image.changingThisBreaksApplicationSecurity.toString();
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }
}
