<div class="card">
<!--  <p-button (onClick)="downloadSimple()" icon="pi pi-check" label="Download" styleClass="p-button-sm"></p-button>-->
  <ngx-doc-viewer
    [url]="data.url"
    style="width:100%;height:100vh;"
    viewer="url"
  ></ngx-doc-viewer>

<!--  <p-button icon="pi pi-check" label="Download" styleClass="p-button-sm" (onClick)="downloadImage(data.url)"></p-button>-->
<!--  <p-image [src]="data.url" alt="Image" class="img"></p-image>-->
</div>
