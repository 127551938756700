import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AppInjector} from "../../app-injector.service";
import {take} from "rxjs";
import {UserService} from "../services/user.service";
import {MessageService} from "primeng/api";

@Component({
  template: ''
})
export abstract class BaseControllerComponent implements OnInit,OnDestroy {

  protected router: Router;
  protected messageActiveRouter: ActivatedRoute;
  protected  userService: UserService;
  protected   messageService: MessageService;
  constructor() {
    AppInjector.getInjector().pipe(take(1)).subscribe((injector) => {
      this.router = injector.get(Router);
      this.messageActiveRouter = injector.get(ActivatedRoute);
      this.userService = injector.get(UserService);
      this.messageService = injector.get(MessageService);
    });
  }
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  messageSuccess(message: string) {
    this.messageService.add({key: 'tc',severity: 'success', summary: message, detail: message});
  }

  messageError(message: string) {

    this.messageService.add({key: 'tc',severity: 'error', summary: message, detail: message});
  }



}
