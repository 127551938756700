import {Component, Input} from "@angular/core";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ChartOptions, primary_color} from "../../chart/chart";
import {ImageViewerComponent} from "../image-viewer/image-viewer.component";
import {BaseControllerComponent} from "../base-components";
import {CandidateService} from "../../services/candidate.service";

@Component({
  selector: "candidate-profil",
  templateUrl: "./candidate-profil.component.html",
  styleUrls: ["./candidate-profil.component.scss"],
})
export class CandidateProfilComponent extends BaseControllerComponent {

  public data;
  @Input() detailCandidate: any;
  public radial: ChartOptions
  public Progresss: any

  constructor(public ref: DynamicDialogRef,
              public dialogService: DialogService,
              public candidateService: CandidateService) {
    super();
    //this.data = this.config.data;
  }



  ngOnInit() {
    this.Progresss = this.detailCandidate?.progress ? this.detailCandidate?.progress : 0
    console.log("DetailCandidate : " + this.detailCandidate)
    if (this.detailCandidate.length > 0) {
      this.detailCandidate.map((x) => {
        if (x.documents != null)
          return x.document
        else
          x.documents = []
      })
    }
    this.CreateReadial(this.Progresss)
  }

  CreateReadial(val){
    this.radial = {
      pieseries: [val],
      chart: {
        height: 200,
        type: "radialBar",
        toolbar: {
          show: false
        }
      },
      colors: [primary_color],
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px"
            },
            value: {
              fontSize: "16px"
            },
            total: {
              show: true,
              label: "Completed",
              formatter: function (w) {
                debugger
                return val + " %";
              }
            }
          }
        }
      },
      labels: ["Apples"]
    };
  }


  displayImage(item)
  {
    this.candidateService.getCandidateDocument({ id: this.detailCandidate.tokenyId,documentId: item.id}).subscribe({
      next: response => {
        console.log("Received", response);
        this.showModalData(response);
      },
      error: error => {
        console.log("unable to get investor detail", error);
      }
    });

  }

  showModalData(responseData)
  {
    console.log("ITEM",responseData);
    this.ref = this.dialogService.open(ImageViewerComponent, {
      data :responseData,
      header: 'Document viewer',
      width: '80%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true
    });

    this.ref.onClose.subscribe((product: any) => {
      if (product) {
        this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: product.name });
      }
    });

    this.ref.onMaximize.subscribe((value) => {
      this.messageService.add({ severity: 'info', summary: 'Maximized', detail: `maximized: ${value.maximized}` });
    });
  }
}
